import {
  faPencilAlt,
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import NutritionTable, {
  nutritionTableColumns
} from 'components/tables/NutritionTable';
import dayjs from 'dayjs';
import { errorHandler } from 'helpers/response-utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useCalendarContext } from 'providers/CalendarProvider';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { REMOVE_EVENT, SET_CALENDAR_STATE } from 'reducers/CalendarReducer';
import FoodPaichart from './FoodPaichart';
import moment from 'moment';

// Define types
type NutritionFact = {
  nutrientName: string;
  value: number;
  unitName: string;
};

type Nutrient = {
  name: string;
  nutritionFact: NutritionFact[];
  quantity: string;
  servingSize: number;
  servingSizeUnit: string;
};

type DataItem = {
  extendedProps: {
    createdAt: number;
    mealFile: string;
    notes: string;
    nutrient: Nutrient[];
  };
};

const CalendarEventModal = () => {
  const { selectedEvent, calendarDispatch } = useCalendarContext();
  const [events, setEvents] = useState(selectedEvent ? selectedEvent : null);
  const [nutrientData, setNutrientData] = useState([]);
  const [modalName, setModalName] = useState('info-modal');
  const { id } = useParams();
  const token = localStorage.getItem('token');
  const [currentDayMeals, setCurrentDayMeals] = useState<DataItem[]>([]);
  const [calculatedTotalFacts, setCalculatedTotalFacts] = useState<
    Record<string, number>
  >({});

  useEffect(() => {
    setEvents(selectedEvent);
  }, [selectedEvent]);

  useEffect(() => {
    if (selectedEvent?.extendedProps.createdAt) {
      getMealByDateRange();
    }
  }, [selectedEvent?.extendedProps.createdAt]);

  const handleClose = () => {
    calendarDispatch({
      type: SET_CALENDAR_STATE,
      payload: {
        selectedEvent: null
      }
    });
  };

  const handleRemove = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ENDPOINT}/clinic/patient/${id}/meal/${selectedEvent?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      calendarDispatch({
        type: REMOVE_EVENT
      });
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const handleCloseEditInfoModal = async () => {
    setModalName('info-modal');
  };

  const handleEdit = async () => {
    setModalName('edit-info-modal');
  };

  const getMealByDateRange = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/clinic/meal/getall`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            startDate: moment(selectedEvent?.extendedProps.createdAt).format(
              'YYYY-MM-DD'
            ),
            endDate: moment(selectedEvent?.extendedProps.createdAt).format(
              'YYYY-MM-DD'
            ),
            patient: id
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      const data = response.data.data.meals;
      setCurrentDayMeals(data);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  useEffect(() => {
    const totals = calculateTotalFacts();
    setCalculatedTotalFacts(totals);
  }, [currentDayMeals]);

  const calculateTotalFacts = (): Record<string, number> => {
    const totals: Record<string, number> = {};

    currentDayMeals.map(item => {
      item.extendedProps.nutrient.map(nutrient => {
        nutrient.nutritionFact.map(fact => {
          const { nutrientName, value } = fact;
          totals[nutrientName] = (totals[nutrientName] || 0) + value;
        });
      });
    });

    return totals;
  };

  const updateMeal = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/clinic/meal`,
        {
          patientID: id,
          mealID: selectedEvent?.id,
          nutrient: events?.extendedProps.nutrient
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      selectedEvent?.setExtendedProp(
        'nutrient',
        events?.extendedProps.nutrient
      );
      handleCloseEditInfoModal();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const handleNutrientChange = async (index: number, newQuantity: any) => {
    setEvents((events: any) => {
      const updatedNutrients = events?.extendedProps.nutrient.map(
        (nutrient: any, i: number) =>
          i === index ? { ...nutrient, quantity: newQuantity } : nutrient
      );

      return {
        ...events,
        extendedProps: {
          ...events.extendedProps,
          nutrient: updatedNutrients
        }
      };
    });
  };

  const nutrientTableData = useAdvanceTable({
    data: selectedEvent?.extendedProps.nutrient,
    columns: nutritionTableColumns,
    pagination: false,
    sortable: false,
    selection: false,
    initialState: {
      columnVisibility: {}
    }
  });
  const color = ['#ED4D18', '#1ACEDA', '#FEB91A', '#4E7CB6', '#A1A1A1'];
  return (
    <>
      <Modal
        centered
        show={!!selectedEvent && modalName == 'info-modal'}
        onHide={handleClose}
        contentClassName="border"
        size="xl"
      >
        <Modal.Body className="px-card pb-card pt-1 fs-9">
          <Row>
            <Col
              className="m-3"
              style={{
                background: '#e8e8e8',
                padding: '10px',
                borderRadius: '10px'
              }}
            >
              <Modal.Title
                className="modal-title text-body-highlight mb-0"
                style={{ display: 'inline' }}
              >
                Overview
              </Modal.Title>
              <Button
                className="p-1"
                style={{ float: 'right' }}
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={faTimes} className="fs-8" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={4} md={4} className="card-image">
              <img
                src={selectedEvent?.extendedProps.mealFile}
                alt=""
                width="85%"
                className="ms-3"
                style={{ border: '10px solid #e8e8e8', borderRadius: '20px' }}
              />
            </Col>

            <Col sm={4} md={4}>
              {selectedEvent?.extendedProps.nutrient ? (
                <FoodPaichart
                  nutrient={selectedEvent?.extendedProps.nutrient}
                />
              ) : null}
            </Col>
            <Col sm={4} md={4}>
              <div>
                <div
                  className={classNames('mt-2', {
                    'border-bottom border-translucent pb-2':
                      selectedEvent?.extendedProps.location
                  })}
                >
                  <p className="mb-1 mt-1">
                    {dayjs(selectedEvent?.extendedProps.createdAt).format(
                      'dddd, MMMM D, YYYY, h:mm A'
                    )}
                    {selectedEvent?.end &&
                      ` – ${
                        dayjs &&
                        dayjs(selectedEvent?.end)
                          .subtract(1, 'day')
                          .format('dddd, MMMM D, YYYY, h:mm A')
                      }`}
                  </p>
                </div>
                <div className="mt-3">
                  <h5 className="mb-3">Total Nutrition for day</h5>
                  <ul>
                    {Object.entries(calculatedTotalFacts)?.map(
                      ([nutrientName, value]) => {
                        return (
                          <li
                            key={nutrientName}
                            style={{
                              listStyle: 'none',
                              margin: '3px 0px',
                              fontSize: '14'
                            }}
                          >
                            <strong>
                              {nutrientName}: {value.toFixed(2)}
                            </strong>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              className="m-3"
              style={{
                background: '#e8e8e8',
                padding: '10px',
                borderRadius: '10px'
              }}
            >
              <Modal.Title
                className="modal-title text-body-highlight mb-0"
                style={{ display: 'inline' }}
              >
                Nutrition details
              </Modal.Title>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} className="mt-3">
              {selectedEvent?.extendedProps?.nutrient?.length ? (
                <AdvanceTableProvider {...nutrientTableData}>
                  <NutritionTable />
                </AdvanceTableProvider>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end px-card pt-0 border-top-0">
          <Button
            onClick={() => handleEdit()}
            variant="phoenix-secondary"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faPencilAlt} className="fs-10" />}
          >
            Edit
          </Button>
          <Button
            onClick={() => handleRemove()}
            variant="phoenix-danger"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faTrash} className="fs-9" />}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={modalName == 'edit-info-modal'}
        onHide={handleCloseEditInfoModal}
        contentClassName="border"
        size="lg"
      >
        <Modal.Header className="ps-card border-bottom border-translucent">
          <div>
            <Modal.Title className="modal-title text-body-highlight mb-0">
              {selectedEvent?.title}
            </Modal.Title>
            {selectedEvent?.extendedProps.organizer && (
              <p className="mb-0 fs-9 mt-1">
                by <Link to="#!">{selectedEvent.extendedProps.organizer}</Link>
              </p>
            )}
          </div>
          <Button className="p-1" onClick={handleCloseEditInfoModal}>
            <FontAwesomeIcon icon={faTimes} className="fs-8" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-card pb-card pt-1 fs-9">
          <div>
            <div
              className={classNames('mt-4', {
                'border-bottom border-translucent pb-3':
                  selectedEvent?.extendedProps.location
              })}
            >
              <div className="mb-1 mt-2">
                {events?.extendedProps.nutrient ? (
                  <>
                    {events?.extendedProps.nutrient.map(
                      (nutrient: any, i: number) => (
                        <Row className="mb-3" key={i}>
                          <Col>
                            <h5> {nutrient.name} </h5>
                          </Col>
                          <Col>
                            <input
                              type="number"
                              className="form-control"
                              value={nutrient.quantity}
                              onChange={e =>
                                handleNutrientChange(i, e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                      )
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end px-card pt-0 border-top-0">
          <Button
            onClick={() => updateMeal()}
            variant="phoenix-secondary"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faPencilAlt} className="fs-10" />}
          >
            Save
          </Button>
          <Button
            onClick={handleCloseEditInfoModal}
            variant="phoenix-danger"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faTrash} className="fs-9" />}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CalendarEventModal;

import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import * as formik from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { patientCreateValidationSchma } from 'static/Patient';
import * as yup from 'yup';

const AddPatient = () => {
  const navigate = useNavigate();
  const { Formik } = formik;

  const token = localStorage.getItem('token');

  const schema = yup.object().shape(patientCreateValidationSchma);
  const genderArray = ['male', 'female'];
  const onSubmit = async (values: any) => {
    const data = {
      name: values.name,
      email: values.email,
      password: values.password,
      phone: values.phone,
      address: values.address,
      fileID: values.fileID,
      birthdate: values.birthdate,
      gender: 'male',
      isDisplayBloodSugar: values.isDisplayBloodSugar == '' ? false : true
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/clinic/patient`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data['status'] == false) {
        toast.error(response.data['error']['message']);
        return false;
      }

      toast(response.data['data']['message']);
      navigateBack();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const navigateBack = () => {
    navigate('/clinic/patient/list');
  };

  return (
    <Card className="col-12">
      <Card.Header>
        <div className="d-sm-flex justify-content-between">
          <h2>Create new patient</h2>
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xl={12}>
            <Formik
              validationSchema={schema}
              onSubmit={values => onSubmit(values)}
              initialValues={{
                name: '',
                email: '',
                password: '',
                phone: '',
                address: '',
                gender: '',
                fileID: '',
                birthdate: '',
                isDisplayBloodSugar: ''
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                setFieldValue
              }) => {
                const generatePassword = (length = 12) => {
                  const charset =
                    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
                  let password = '';
                  for (let i = 0; i < length; i++) {
                    const randomIndex = Math.floor(
                      Math.random() * charset.length
                    );
                    password += charset[randomIndex];
                  }
                  setFieldValue('password', password);
                };
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className={classNames('g-3', 'mb-6')}>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Fullname</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={touched.name && !!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {' '}
                            {errors.name}{' '}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Contact</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Civil ID</Form.Label>
                          <Form.Control
                            type="text"
                            name="fileID"
                            value={values.fileID}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Birthdate</Form.Label>
                          <Form.Control
                            type="date"
                            name="birthdate"
                            value={values.birthdate}
                            onChange={handleChange}
                            isValid={touched.birthdate && !errors.birthdate}
                            isInvalid={touched.birthdate && !!errors.birthdate}
                          />
                          <Form.Control.Feedback type="invalid">
                            {' '}
                            {errors.birthdate}{' '}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Gender</Form.Label>
                          <Form.Select
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                            isValid={touched.gender && !errors.gender}
                            isInvalid={touched.gender && !!errors.gender}
                          >
                            <option value=""> -- Select -- </option>
                            {genderArray.length
                              ? genderArray.map((gender, key) => (
                                  <option value={gender} key={key}>
                                    {' '}
                                    {gender.charAt(0).toUpperCase() +
                                      gender.slice(1)}{' '}
                                  </option>
                                ))
                              : ''}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {' '}
                            {errors.gender}{' '}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={8} md={8}>
                        <Row className={classNames('g-3', 'mb-6')}>
                          <Col sm={6} md={6}>
                            <Form.Group className="mb-2">
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={values.password}
                                disabled
                                onChange={handleChange}
                                isValid={touched.password && !errors.password}
                                isInvalid={
                                  touched.password && !!errors.password
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {' '}
                                {errors.password}{' '}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={3} md={3}>
                            {' '}
                            <br />
                            <Button
                              variant="outline-primary"
                              className="me-2 px-6"
                              onClick={() => generatePassword(12)}
                            >
                              Generate
                            </Button>{' '}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12} md={12}>
                        <Form.Group className="mb-2">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            rows={4}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} md={12}>
                        <Form.Group className="mb-2">
                          <Form.Check
                            type="switch"
                            name="isDisplayBloodSugar"
                            value={values.isDisplayBloodSugar}
                            id="isDisplayBloodSugar"
                            label="Would you like to know your patient blood sugar?"
                            checked={values.isDisplayBloodSugar ? true : false}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <div className="text-end">
                        <Button
                          variant="phoenix-primary"
                          className="me-2 px-6"
                          onClick={navigateBack}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary">
                          Create
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AddPatient;

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { GridComponent } from 'echarts/components';
echarts.use([TooltipComponent, PieChart, GridComponent, CanvasRenderer]);

interface NutritionFactInterFace {
  value: any;
  nutrientName: string;
  unitName: string;
}
interface NutrientInterFace {
  nutritionFact: Array<NutritionFactInterFace>;
  quantity: string;
  name: string;
  servingSize: number;
  servingSizeUnit: string;
}

interface TotalNutrients {
  [key: string]: {
    value: number;
    unitName: string;
  };
}

const getDefaultOptions = (
  getThemeColor: (name: string) => string,
  chartData: any,
  totalEnergy: any
) => {
  // Calculate Total Value
  const total = parseInt(
    totalEnergy.reduce((sum: number, item: any) => sum + item.value, 0)
  );

  return {
    color: ['#ED4D18', '#1ACEDA', '#FEB91A', '#4E7CB6', '#A1A1A1'], // Custom colors
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {d}%' // Show name and percentage
    },
    responsive: true,
    maintainAspectRatio: false,
    series: [
      {
        name: 'Total Calories',
        type: 'pie',
        radius: ['50%', '70%'],
        center: ['50%', '50%'],
        startAngle: 90, // Adjust angle if needed
        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'center',
          formatter: `{b|${total}}\n{a|Total Kcal}`, // Show total in center
          rich: {
            b: {
              fontSize: 28,
              fontWeight: 800,
              color: '#333'
            },
            a: {
              fontSize: 14,
              fontWeight: 600,
              color: '#666'
            }
          }
        },
        emphasis: {
          label: {
            show: true
          }
        },
        labelLine: {
          show: false
        },
        data: chartData // Dynamic data
      }
    ],
    grid: {
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      containLabel: false
    }
  };
};

const getFormatedNutrient = (nutrient: Array<NutritionFactInterFace>) => {
  const totalNutrients: TotalNutrients = {};

  // Loop through each food item
  nutrient.forEach((item: any) => {
    item.nutritionFact.forEach((nutrient: NutritionFactInterFace) => {
      const { nutrientName, value, unitName } = nutrient;
      if (nutrientName !== 'Energy') {
        if (!totalNutrients[nutrientName]) {
          totalNutrients[nutrientName] = { value: 0, unitName };
        }
        totalNutrients[nutrientName].value += value;
      }
    });
  });

  // Convert the result to an array of objects
  const totalNutritionArray = Object.keys(totalNutrients).map(nutrientName => ({
    value: totalNutrients[nutrientName].value,
    name: nutrientName
  }));
  return totalNutritionArray;
};

const getTotalEnergy = (nutrient: Array<NutritionFactInterFace>) => {
  const totalNutrients: TotalNutrients = {};

  // Loop through each food item
  nutrient.forEach((item: any) => {
    item.nutritionFact.forEach((nutrient: NutritionFactInterFace) => {
      const { nutrientName, value, unitName } = nutrient;
      if (nutrientName === 'Energy') {
        if (!totalNutrients[nutrientName]) {
          totalNutrients[nutrientName] = { value: 0, unitName };
        }
        totalNutrients[nutrientName].value += value;
      }
    });
  });

  // Convert the result to an array of objects
  const totalNutritionArray = Object.keys(totalNutrients).map(nutrientName => ({
    value: totalNutrients[nutrientName].value,
    name: nutrientName
  }));
  return totalNutritionArray;
};
const FoodPaichart = (props: any) => {
  const { getThemeColor } = useAppContext();
  const chartData = getFormatedNutrient(props.nutrient);
  const totalEnergy = getTotalEnergy(props.nutrient);
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(getThemeColor, chartData, totalEnergy)}
      style={{ minHeight: '280px' }}
    />
  );
};

export default FoodPaichart;
